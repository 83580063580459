import type { GetServerSidePropsContext } from 'next';

import { DefaultPageLayout } from '@/components';
import { getDefaultServerSideProps } from '@/components/layout/layout.page.props';

export const getServerSideProps = async (
  initialProps: GetServerSidePropsContext,
) => {
  return await getDefaultServerSideProps(initialProps);
};

export default DefaultPageLayout;
